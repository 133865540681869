@import "styles/variables";

.benefitsItem {
  display: flex;
  align-items: center;
  padding: 0 15px;
  @media (max-width: $md) {
    flex-direction: column;
    align-items: center;
    .text{
      text-align: center;
      margin-top: 10px;
    }
  }

  .text {
    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: #1D428A;
      max-width: 180px;
      @media (max-width: $md) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  :global(.icon){
    min-width: 30px;
    @media (max-width: $md) {
      margin-right: 0 !important;
    }
  }
}


.benefitsContainer {
  .benefitsDots {
    bottom: -10px;
    margin-bottom: 0;
  }
}