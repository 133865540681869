$footer-color: rgb(57, 12, 99);

$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1440px;

$general-gutter: 15px;
$general-gutter-05: 7px;
$general-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px;
$general-radius: 2px;
$general-radius-lg: 16px;

$general-transition: all .3s;

$discount-color: rgb(230, 59, 69);
$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);

$primary-color: #1D428A;
$primary-color-hover: #0c3179;

$secondary-color: #F1C400;
$alt-color: #F1F1F7;
$text-color: rgb(17, 17, 17);
$blue-color: rgb(0, 89, 255);
$grey-color: rgb(183, 183, 183);
$grey-light-color: rgb(238, 238, 238);
$grey-dark-color: rgb(172, 172, 172);

$main-bg-color: rgb(255,255,255);