@import 'styles/variables';

.operationUnitsBanner {
  background: $secondary-color;
  padding: 25px;
  border-radius: 28px;
  overflow: visible;
  margin: 50px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 150px;
    padding: 30px 60px;
  }

  .text {
    h1 {
      font-size: 35px;
      line-height: 45px;
    }

    p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 20px;
    }
  }



  .image {
    display: none;
    @media (min-width: $lg) {
      display: block;
    }
  }

  .link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
    white-space: nowrap;
    margin-top: 40px;

    @media (min-width: $md) {
      margin-top: 0
    }

    .icon {
      background: $primary-color;
      margin-left: 20px;
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 12px;

      svg {
        color: $secondary-color;
      }
    }

    &:hover svg {
      animation: bounceright .3s alternate ease infinite;
    }
  }
}

@keyframes bounceright {
  from { transform: translateX(-2px); }
  to   { transform: translateX(3px); }
}

