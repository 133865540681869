@import "styles/variables";

.card{
	max-width: 300px;
	display: block;
	border-radius: $general-radius-lg;
	background: $main-bg-color;
	margin: 30px $general-gutter;
	box-shadow: $general-shadow;
	position: relative;
	@media (max-width: $sm) {
		margin: 23px 10px;
		border-radius: 14px;
	}
	&.isSecond{
		.content{
			background: $primary-color;
			color: $secondary-color;
			h3{
				color: $secondary-color;
			}
			a{
				color: $main-bg-color;
			}
			.icon{
				background: $secondary-color;
				svg{
					color: $primary-color;
				}
			}
		}
	}
	&.isThird{
		.content{
			background: $alt-color;
			color: $text-color;
			a{
				color: $text-color;
			}
		}
	}
	&:hover{
		.icon{
			svg{
				transform: rotate(-90deg);
			}
		}
	}
	.image{
		display: flex;
		justify-content: center;
		margin: 0 auto;
		overflow: hidden;
		border-top-right-radius: $general-radius-lg;
		border-top-left-radius: $general-radius-lg;
		picture{
			max-width: 100%;
		}
	}
	.content{
		background: $secondary-color;
		color: $primary-color;
		padding: 25px;
		border-bottom-right-radius: $general-radius-lg;
		border-bottom-left-radius: $general-radius-lg;
		min-height: 150px;
		@media (max-width: $sm){
			min-height: unset;
			padding: $general-gutter;
		}
		span{
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			overflow: hidden;
		}
		h3{
			font-size: 24px;
			color: $primary-color;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			min-height: 57px;
			@media (max-width: $sm) {
				font-size: 14px;
				min-height: 33px;
			}
		}
		a{
			font-size: 12px;
			cursor: pointer;
			display: block;
			&::before{
				position: absolute;
				cursor: pointer;
				content: "";
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			&:hover{
				text-decoration: underline;
			}
		}
		.icon{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			background: $primary-color;
			border-radius: 20px;
			bottom: -20px;
			right: 30px;
			position: absolute;
			@media (max-width: $sm){
				width: 24px;
				height: 24px;
				bottom: -12px;
				svg{
					font-size: 12px;
				}
			}
			svg{
				transition: $general-transition;
				color: $secondary-color;
			}
		}
	}

}
