@import "styles/variables";

.mobileBanners {
  display: block;
  @media screen and (min-width: $xs) {
    display: none;
  }
  @media (max-width: $sm) {
    padding-bottom: 34px;
  }
}

.desktopBanners {
  display: none;
  position: relative;
  padding-bottom: 60px;
  @media (max-width: 1200px) {
    padding: 0 20px 60px 20px;
  }
  @media screen and (min-width: $xs) {
    display: block;
  }
  .dotsSlider{
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 100%;
    background: $main-bg-color;
    height: 60px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    max-width: 100%;
    display: flex;
    padding: 5px 10px;
  }
  .dotsWrap{
    display: flex;
    gap: $general-gutter;
    padding: 0 10px;
    align-items: center;
    overflow: auto;
    @media (max-width: 1200px){
      border-radius: 0;
      left: 19px;
      width: calc(100% - 39px);
    }
    &::-webkit-scrollbar {
      height: 5px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($grey-color, .5);
      border-radius: $general-radius;
    }

    .dot{
      border-radius: 20px;
      height: 36px;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 14px;
      color: $primary-color;
      padding: 0 $general-gutter;
      transition: $general-transition;
      &.active{
        color: $secondary-color;
        background: $primary-color;
        font-weight: bold;
        svg{
          transform: rotate(-90deg);
        }
      }
      span{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        white-space: nowrap;
      }
      svg{
        font-size: 16px;
        min-width: 16px;
        transition: $general-transition;
      }
    }
  }
}